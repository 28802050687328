import { TFormData, TJourneyConfig } from '../types';
import { HTMLField } from './FormFields/HTMLField';

export const RegulatoryFooter = (props: { config: TJourneyConfig; formData: TFormData }) => {
  const { config, formData } = props;
  if (!config.product.footer) {
    return null;
  }

  if (config.product.footer?.type === 'html') {
    return (
      <footer style={{ textAlign: 'center', margin: 16, color: '#707070' }}>
        <HTMLField field={config.product.footer} />
      </footer>
    );
  }

  if (
    formData?.values?.quote?.insurerName ===
      'Helvetia Schweizerische Versicherungsgesellschaft in Liechtenstein AG' &&
    config.product.HelvetiaFooter
  ) {
    config.product.footer = config.product.HelvetiaFooter;
  }

  if (
    formData?.values?.quote?.insurerName?.includes("Collinson") &&
    config.product.CollinsonFooter
  ) {
    config.product.footer = config.product.CollinsonFooter
  }

  return (
    <footer style={{ textAlign: 'center', margin: 16, color: '#707070' }}>
      {config.product.footer.map((footer: any, index: number) => (
        <div key={index}>
          <p>
            <small>{footer}</small>
          </p>
          <br />
        </div>
      ))}
    </footer>
  );
};
