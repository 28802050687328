import { IStringIndex } from "../types";

// PIERS
import { Product } from "./PIERS/getProduct.js";
import { PartyType } from "./PIERS/getPartyType.js";
import { PreferredContactMethod } from "./PIERS/getPreferredContactMethod";
import { AbodeType } from "./PIERS/getAbodeType";
import { Cover } from "./PIERS/getCover";
import { VehicleType as PiersVehicleType } from "./PIERS/getVehicleType";
import { RadiusOfOperation } from "./PIERS/getRadiusOfOperation";
import { PublicLiabilityLevel } from "./PIERS/getPublicLiabilityLevel";
import { NonStandardUse } from "./PIERS/getNonStandardUse";
import { CameraType } from "./PIERS/getCameraType";
import { RiskClass } from "./PIERS/getRiskClass";
import { ReleaseType } from "./PIERS/getReleaseType";
import { DrivingFrequency } from "./PIERS/getDrivingFrequency";
import { AffinityGroup as PiersAffinityGroup } from "./PIERS/getAffinityGroup";
import { PermittedDriver } from "./PIERS/getPermittedDrivers";

// Polaris
import { Title } from "./Polaris/53-Title.js";
import { Sex } from "./Polaris/5-Sex.js";
import { VehicleType as PolarisVehicleType } from "./Polaris/10-VehicleType";
import { VehicleUse } from "./Polaris/52-VehicleUse";
import { FuelType } from "./Polaris/109-FuelType";
import { TransmissionType } from "./Polaris/110-TransmissionType";
import { VehicleValueIndicator } from "./Polaris/105-VehicleValueIndicator";
import { VehicleLocationWhenNotInUse } from "./Polaris/15-VehicleLocationWhenNotInUse";
import { VehicleOwnerKeeperStatus } from "./Polaris/27-VehicleOwnerKeeperStatus";
import { TaxiPSVLicencingAuthorities } from "./Polaris/586-TaxiPSVLicencingAuthorities";
import { NCBIntroEntitlementReason } from "./Polaris/83-NCBIntroEntitlementReason";
import { VehicleModification } from "./Polaris/86-VehicleModification";
import { SecurityDeviceManufacturerAndModel } from "./Polaris/76-SecurityDeviceManufacturerAndModel";
import { SecurityDeviceFittedBy } from "./Polaris/77-SecurityDeviceFittedBy";
import { EmployersBusinessCategory } from "./Polaris/11-EmployersBusinessCategory";
import { OccupationCode } from "./Polaris/55-OccupationCode";
import { EmploymentType } from "./Polaris/56-EmploymentType";
import { ClaimType } from "./Polaris/22-ClaimType";
import { ConvictionCode } from "./Polaris/23-ConvictionCode";
import { AlcoholicReadingType } from "./Polaris/39-AlcoholicReadingType";
import { ConvictionType } from "./Polaris/411-ConvictionType";
import { MaritalStatus } from "./Polaris/6-MaritalStatus";
import { RelationshipToProposer } from "./Polaris/19-RelationshipToProposer";
import { DrivingLicence } from "./Polaris/20-DrivingLicence";
import { DriverProficiencyQualification } from "./Polaris/38-DriverProficiencyQualification";
import { MedicalCondition } from "./Polaris/3-MedicalCondition";
import { Occupations } from "./AOIP/getOccupation";

// CEDAR
import { Titles } from "./CEDAR/getTitles";
import { Trade } from "./CEDAR/getTrade";

export type TDataSource = {
  providerName: string;
  sourceName: string;
};

type TOptionList = Array<{ label: string; value: any }>;
type TProvider = IStringIndex<TOptionList>;

const PIERS: TProvider = {
  Product,
  PartyType,
  PreferredContactMethod,
  AbodeType,
  Cover,
  VehicleType: PiersVehicleType,
  RadiusOfOperation,
  PublicLiabilityLevel,
  NonStandardUse,
  CameraType,
  RiskClass,
  ReleaseType,
  DrivingFrequency,
  AffinityGroup: PiersAffinityGroup,
  PermittedDriver,
};

const Polaris: TProvider = {
  Title,
  Sex,
  VehicleType: PolarisVehicleType,
  VehicleUse,
  FuelType,
  TransmissionType,
  VehicleValueIndicator,
  VehicleLocationWhenNotInUse,
  VehicleOwnerKeeperStatus,
  TaxiPSVLicencingAuthorities,
  NCBIntroEntitlementReason,
  VehicleModification,
  SecurityDeviceManufacturerAndModel,
  SecurityDeviceFittedBy,
  EmployersBusinessCategory,
  OccupationCode,
  EmploymentType,
  ClaimType,
  ConvictionCode,
  AlcoholicReadingType,
  ConvictionType,
  MaritalStatus,
  RelationshipToProposer,
  DrivingLicence,
  DriverProficiencyQualification,
  MedicalCondition,
};

const AOIP: TProvider = {
  Occupations,
};

const CEDAR: TProvider = {
  Titles,
  Trade,
};

const Providers: IStringIndex<TProvider> = {
  PIERS,
  Polaris,
  AOIP,
  CEDAR,
};

export const getDataSourceOptions = (dataSource: TDataSource) => {
  const provider = Providers[dataSource.providerName];
  if (provider) {
    if (provider[dataSource.sourceName]) {
      return provider[dataSource.sourceName];
    } else {
      console.error(
        `Unknown sourceName "${dataSource.sourceName}" for provider "${dataSource.providerName}"`
      );
    }
  } else {
    console.error(`Unknown datasource provider "${dataSource.providerName}"`);
  }
  return [];
};